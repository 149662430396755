<template>
  <breadcrumb-page :list="breadcrumb_links">></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="CareerEvents"></company-submenu>
      
      <div class="submenu-content">
            <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <div v-for="(hero,index) in careerEvents_banner" :key="hero">
          <div v-if="index == 0">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="index"
              class="active"
              aria-current="true"
            ></button>
          </div>
          <div v-else>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="index"
            ></button>
          </div>
        </div>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item" v-for="(hero,index) in careerEvents_banner" :class="{active:index==0}" :key="hero"> 
          <a :href="hero[1]"><img :src="hero[0]" class="d-block w-100"/></a>
        </div>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
       

        <div class="container-fluid">
          <div v-html="careerEvents_para" class="paragraph"></div>

          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Career Events</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Location</th>
              </tr>             
            </thead>
            <tbody>
                <tr v-for="event in careerEvents_table" :key="event">
                    <td><a :href="event[1]" target="blank">{{event[0]}}</a></td>
                    <td>{{event[2]}}</td>
                    <td>{{event[3]}}</td>
                    <td>{{event[4]}}</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>

  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from "../../utils/axios-api";
import { ref } from "vue";

export default {
  name: "CareerEvents",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const careerEvents_banner = ref({});
    const careerEvents_para = ref("");
    const careerEvents_table = ref([]);
    const error = ref(null);

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Careers", link: "Careers"})
    breadcrumb_links.value.push({text: "Career Events", link: "CareerEvents"})

    let careerEvents_url = "/content/pages/49";
    getAPI
      .get(careerEvents_url)
      .then((response) => {
        if (response.status === 200) {
          let blocks = response.data.body;
          for (let index in blocks) {
            if (blocks[index].type == "image") {
              careerEvents_banner.value[index] = [blocks[index].value.large.src,index];
            }
            if (blocks[index].type == "paragraph") {
              careerEvents_para.value = blocks[index].value;
            }
            if (blocks[index].type == "table") {
              let events = blocks[index].value.data.data;
              for (let i in events) {
                if (events[i] != null) {
                  careerEvents_table.value.push(events[i]);
                }
              }
              console.log(careerEvents_table.value)
            }
          }
        }
      })
      .catch((err) => {
        error.value = "No page Found";
      });
    return {
      careerEvents_banner,
      careerEvents_para,
      careerEvents_table,
      error,
      breadcrumb_links
    };
  },
};
</script>

<style></style>
